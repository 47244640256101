import React, { Component } from 'react';

export default class Errors extends Component {

	constructor(props) {
    super(props);
    this.compose = this.compose.bind(this);
    this.state = {
			message: false,
			description: false,
			autoclose: false
		}
	}

	compose() {

		let message = false;
		let description = false;
		let hint = false;
		let autoclose = false;
		let timeout = false;
		let messageType = false;
		let error = this.props.error;

		if(error === 'NO-ACCESS') { // wrong credentials

			message = 'Sorry, your username and password do not match.';
			description = 'Check your username and password.';

		} else if(error === 'NO-AUTH') { // session expired

			message = '';
			description = false;

		} else if(error === 'NO-CONTENT') { // No content to upload

			message = 'No content to publish';
			description = 'You cannot publish a message without any content.';

		} else if(error === 'POST-REQ-NOK') {
			
			message = 'Your message did not publish.';
			description = 'Make sure you have set a location, some content and chosen expiration time.'

		} else if(error === 'NO-FILE') {

			message = 'Something went wrong with the file upload.';
			description = 'Sorry, the file is not uploaded.'

		} else if(error === 'NO-CONN') {

			message = 'Cannot connect to server';
			description = 'Please try again later.'

		} else if(error === 'ERR-EMAIL') {

			message = 'Sorry, something is wrong with the e-mail address. Please check.';
			description = 'Make sure you entered a valid e-mail address'

		} else if(error === 'ERR-AUTH') {
		
			message = 'Wrong authorization data.';
			description = 'Make sure you are using the latest log-in link we have sent you.'
		
		} else if(error === 'ACCC-LINK') {
		
			message = 'Sorry - wrong or expired link.';
			description = 'The URL is incorrect or used already.';
		
		} else if(error === 'EMAIL-EXISTS') {
		
			message = 'E-mail address already in use.';
			description = 'An account is already linked to the given e-mail address. Log in instead.';
		
		} else if(error === 'REG-FAILED') {
		
			message = 'Sorry, registration failed.';
			description = 'This is a system error. So-so sorry! Please try again later.';
		
		} else if(error === 'PASSWD') {
		
			message = 'Wrong password.';
			description = 'The password you entered is not correct.';
		
		} else if(error === 'NO-RESP') {

			message = 'Sorry, the server is busy';
			description = 'The server didn\'t respond. Please try again later.';
		
		} else if(error === 'NO-PERM') { // update-message

			message = 'You have no permissions';
			description = 'You cannot do it.';
			
		} else if(error === 'NO-PERM-SERVICE') { // update-message

			message = 'No such page';
			description = 'Please make sure you entered the URL correctly';
		
		} else if(error === 'NO-POST') {
			
			message = 'No messages nearby at the moment';
			
			if(this.props.data.authorized === true && this.props.data.settings.autorefresh === false) {
			
				description = false;
				hint =  'Turn on auto-check from the menu';
				timeout = 6000;
				messageType = "status";
			
			} else if(this.props.data.authorized === true && this.props.data.settings.autorefresh === true) {	
				
				message = false;
				description = 'No messages nearby at the moment';
				hint =  "Auto-checking once per minute";
				timeout = 6000;
				messageType = "status";
			
			} else  {
				
				message = false;
				description = 'No messages nearby at the moment';
				hint = false;
				timeout = 6000;
				messageType = "status";
			}
			
			autoclose = true;
			
		
		} else if(error === 'NO-TAGS') {

			message = 'No hashtagged messages';
			timeout = 6000;
			autoclose = true;
			messageType = "status";
		
		}  else if(error === 'HAS-POST') {

			description = Object.keys(this.props.posts).length ? Object.keys(this.props.posts).length === 1 ? '1 message' : Object.keys(this.props.posts).length + ' messages' : 'No messages';
			autoclose = true;
			timeout = 6000;
			messageType = "status";
			
		} else if(error === "ERR") {
			
			description = 'Sorry! System error.';
			hint = "Something went wrong. Please try again later.";
			
		} else if(error) {
			
			message = this.props.error;
		
		} 
		
		if(message || description) {
			
			if(autoclose === true && this.props.errorUpdate) {

				if(window.errorTimer) {
					clearTimeout(window.errorTimer);
				}
				
				window.errorTimer = setTimeout(
					function() {
						this.props.errorUpdate({ error: false });
						clearTimeout(window.errorTimer);
					}
					.bind(this),
					timeout
				);
			}
			
			return (
				<div id="error" className= { autoclose === true ? error.toLowerCase() + " autoclose " + messageType : error.toLowerCase() + " error " + messageType } onClick={ () => this.props.errorUpdate({ error: false }) }>
					<div className="message">
						{ message ? <h2>{ message }</h2> : false }
						{ description ? <p>{ description }</p> : false }
						{ hint ? <p className="hint">{ hint }</p> : false }
					</div>
				</div>
			)

		} else {

			return false;
		}
	}
	
	
	
	render() {

		if(this.props.error === false) return false;
		return ( this.compose() );

	}
}
